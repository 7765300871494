import { render, staticRenderFns } from "./new-navbar.vue?vue&type=template&id=e4a65926&scoped=true"
import script from "./new-navbar.vue?vue&type=script&lang=js"
export * from "./new-navbar.vue?vue&type=script&lang=js"
import style0 from "./new-navbar.vue?vue&type=style&index=0&id=e4a65926&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e4a65926",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ModalAuthen: require('/app/components/modal/authen.vue').default})
